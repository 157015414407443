import React, {useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';

function Spinner() {
  return <div style={spinnerStyle}></div>;
}

const spinnerStyle = {
  border: '16px solid #f3f3f3',
  borderTop: '16px solid #3498db',
  borderRadius: '50%',
  width: '60px',
  height: '60px',
  marginBottom: '20px',
  animation: 'spin 2s linear infinite',
};

const spinnerAnimation = `
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
`;

function IframePage() {
  const { id } = useParams();

  const iframeSrc = id
    ? `https://invoices.understandify.com/proxy?id=${id}`
    : 'https://invoices.understandify.com/proxy';

    const [loading, setLoading] = useState(true);
    const [pdfLoading, setPdfLoading] = useState(false);

    const generatePdf = async () => {
      setPdfLoading(true);
      try {
        const response = await axios.post(
          `https://invoices.understandify.com/pdf/${id}`,
          {},
          {
            responseType: 'blob',
          }
        );
    
        const url = window.URL.createObjectURL(new Blob([response.data]));
    
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `invoice-${id}.pdf`);
        document.body.appendChild(link);
        link.click();
    
        link.parentNode.removeChild(link);
      } catch (error) {
        console.error('Error downloading PDF:', error);
      } finally {
        setPdfLoading(false);
      }
    };

  return (
    <div style={{position: 'relative'}}>
      <style>{spinnerAnimation}</style>
      {(loading || pdfLoading) && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            zIndex: 1,
            display: 'flex', 
            flexDirection: 'column'
          }}
        > 
          <Spinner />
          {loading && <div>Loading...</div> }
          {pdfLoading && <div>Generating PDF...</div>}
        </div>
      )}
      <iframe
        src={iframeSrc}
	name="invoice"
        title="Invoice Page"
        style={{ width: '100%', height: '110vh', border: 'none' }}
        onLoad={() => setLoading(false)}
        id="invoice"
      ></iframe>
      {(iframeSrc && !loading) &&
      <div style={{textAlign: 'end', padding:'0 50px 20px 0'}}><a href="#" onClick={generatePdf}>
        Click here
      </a> to download pdf</div>}
    </div>
  );
}

function App() {
  return (
    <>
    <Helmet>
      <title>Understandify Invoice</title>
    </Helmet>
    <Router>
      <Routes>
        <Route path="/invoice/:id" element={<IframePage />} />
        <Route path="/" element={<IframePage />} />
      </Routes>
    </Router>
    </>
  );
}

export default App;
